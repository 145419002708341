import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Page} from "../../entities"
import {Promo} from "../../entities/Promo";
import {FindCountryFromMatrixByUrlCode} from "../../process/matrix";

type State =
{
  //
  promo: Promo

  // selected country
  country: number,

  // select citizenship
  citizenship: number,

  // selected visa type
  // visaType not used since  1.7.45 now we use pageId
  // visaType: number,

  // selected page,
  // from there we take
  page: number,

  // selected cost
  cost: number,

  // pages loaded on calc page
  // also used on addons page
  pages: Page[]

  // payment data
  payment: {
    // selected payment string
    code: string,

    // additional payment data
    data: {
      comment?:string
    }
  }
}

let initialState:State =
{
  country: 0,
  citizenship: 0,
  // visaType: 0,
  page: 0,
  cost: 0,

  promo: {
    code: "",
    discountPercent: 0
  },

  pages: [],

  payment: {
    code: '',
    data: {}
  }
}

// set default citizenship
if (window.calcConfig.calc.citizenship && window.calcConfig.calc.citizenship > 0) {
  console.info('citizenship received from server config '+window.calcConfig.calc.citizenship)
  initialState.citizenship = window.calcConfig.calc.citizenship;
}

// get country from url
const getCountryUrlPart = () : string|null =>
{
  const path = (document.location && document.location.pathname)
  if (!path) return null;

  const pices = document.location.pathname.split('/')
  if (pices[1] && pices[1] === 'calc' && pices[2]) {
    return pices[2]
  }
  if (pices[1] && pices[1] === 'visa' && pices[2]) {
    return pices[2]
  }
  return null;
}

const countryUrlPart = getCountryUrlPart();
if (countryUrlPart) {
  const country = FindCountryFromMatrixByUrlCode(countryUrlPart);
  if (country) {
    console.info('country selected '+country.id+' from url: '+countryUrlPart)
    initialState.country = country.id;
  }
}


if (window.sessionStorage) {
  const s = window.localStorage.getItem('vtgCalc');
  if (s) {
    let data = JSON.parse(s)

    initialState.country = data.calcSlice.country
    initialState.citizenship = data.calcSlice.citizenship
    // initialState.visaType = data.calcSlice.visaType
    initialState.page = data.calcSlice.page
    initialState.cost = data.calcSlice.cost

    // load promo data
    if (data.calcSlice && data.calcSlice.promo) {
      initialState.promo = data.calcSlice.promo
    }

    // payment
    if (data.payment && data.payment.data) {
      initialState.payment.data = data.payment.data
    }
  }
}

const calcSlice = createSlice({
  name: 'calc',
  initialState,
  reducers: {
    setPages(state:State, action:PayloadAction<Page[]>) {
      state.pages = action.payload
    },
    clearPages(state:State) {
      state.pages = []
    },
    setPromoCode(state:State, action:PayloadAction<string>) {
      state.promo.code = action.payload
    },
    setCitizenship(state:State, action:PayloadAction<number>) {
      state.citizenship = action.payload
    },
    setCountry(state:State, action:PayloadAction<number>) {
      state.country = action.payload
    },
    // setVisaType(state:State, action:PayloadAction<number>) {
    //   state.visaType = action.payload
    // },
    setSelectedPage(state:State, action:PayloadAction<number>) {
      state.page = action.payload
    },
    setCost(state:State, action:PayloadAction<number>) {
      state.cost = action.payload
    },
    clearSelectedCost (state:State){
      state.cost = 0
    },
    setPaymentCode(state:State, action:PayloadAction<string>) {
      state.payment.code = action.payload
    },
    setPaymentData(state:State, action:PayloadAction<object>) {
      state.payment.data = action.payload
    },
    clearPayment(state:State) {
      state.payment = {
        code: '',
        data: {}
      }
    },

  }
})

export const {
  setPages,
  clearPages,
  setPromoCode,
  setCitizenship,
  setCountry,
  // setVisaType,
  setSelectedPage,
  setCost,
  clearSelectedCost,
  setPaymentCode,
  setPaymentData,
  clearPayment
} = calcSlice.actions

export default calcSlice.reducer
