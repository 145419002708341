import React, {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import BigLoader from "../Components/BigLoader.js";
// import {default as Header} from "../widgets/Header"
// import {Footer} from "../widgets/Footer"
// import {default as MobileMenu} from "../widgets/MobileMenu";
// import {default as LoginOnClientSideHOC} from "../widgets/LoginOnClientSideHOC";
import "../assets/scss/App.scss"

const Index = lazy(() => import('../Pages/Index'));
const Calc = lazy(() => import('../Pages/Calc'));
const Order = lazy(() => import('../Pages/Order'));
const Checkout = lazy(() => import('../Pages/Checkout'));
const Addons = lazy(() => import('../Pages/Addons'));

const About = lazy(() => import('../Pages/About'));
const Partnership = lazy(() => import('../Pages/Partnership'));
const CountryGuidelines = lazy(() => import('../Pages/CountryGuidelines'));
const Policy = lazy(() => import('../Pages/Policy'));
const Terms = lazy(() => import('../Pages/Terms'));
const CitizenshipOfGrenada = lazy(() => import('../Pages/CitizenshipOfGrenada'));

const Landings = lazy(() => import('../Pages/Landings'));

const Page404 = lazy(() => import('../Pages/404/index'));

const UploadPage = lazy(() => import('../Pages/Upload/Upload'));
const LoginToUploadPage = lazy(() => import('../Pages/Upload/Login'));
const RestoreToUploadPage = lazy(() => import('../Pages/Upload/Restore'));

const Partner = lazy(() => import('../Pages/Partner/Index/index'));
const PartnerStatistic = lazy(() => import('../Pages/Partner/Statistic/index'));
const PartnerPayouts = lazy(() => import('../Pages/Partner/Payouts/index'));
const PartnerTools = lazy(() => import('../Pages/Partner/Tools/index'));
//

declare global {
  interface Window {
    calcConfig: {
      calc: {
        citizenship?: number,
        country?: number,
      },
      matrix:  Record<string, any>, /*{
        // delivery: Delivery[],
        citizenship?: Citizenship[],
        countries?: Country[],
        countriesToIndex?: CountryOnIndex[],
        payments?: Payment[],
      },*/
      params: {
        partnerId?: number,

        contactEmail?: string,
        contactPhone?: string,
        formatContactPhone?: string,

        // Information about document upload limits
        availableFileTypes?: Array<string>, //
        availableMimeTypes?: Array<string>,
        maxFileSize?: number, // 1Mb, 200Kb, etc

        // footer menus
        footerAboutMenu: Array<{title:string, url:string}>,
        // footerGuideMenu: Array<{title:string, url:string}>,
        footerLegalMenu: Array<{title:string, url:string}>,

        // Limit on the number of countries displayed in the basement
        countCountriesInFooter?: null,

        // api urls
        urls: {
          calc?: string
          save?: string
          addons?: string
          checkout?: string

          // upload files api
          uploadFiles?:string
          uploadFilesAuth?: string
          uploadFilesRestore?: string
          uploadFilesList?: string,
          uploadFilesStoreComment?: string,

          //
        }
      },
      // translates
      i18n: {[key: string]: string};
    };
  }
}


// const LazyApp = lazy(() => import('./LazyApp'));

function App(props:{store:any}) {
  return <Suspense fallback={<Preloader />}>
    {/*<MobileMenu />*/}
    {/*<LoginOnClientSideHOC>*/}
    {/*  <div className="App">*/}
    {/*    <div className="App_content">*/}
    {/*      <Header />*/}
          <Router>
            <Routes>
              <Route path="/" element={<Index/>}/>

              <Route path="/visa" element={<Calc/>}/>
              <Route path="/visa/:countryUrlCode" element={<Calc/>}/>
              <Route path="/order" element={<Order/>}/>
              <Route path="/addons" element={<Addons/>}/>
              <Route path="/checkout" element={<Checkout/>}/>

              {/* pages bottom nit in layout */}
              <Route path="/partnership" element={<Partnership/>}/>
              <Route path="/about" element={<About/>}/>
              <Route path="/guidelines" element={<CountryGuidelines/>}/>
              <Route path="/policy" element={<Policy />}/>
              <Route path="/terms" element={<Terms/>}/>
              <Route path="/citizenship/grenada" element={<CitizenshipOfGrenada/>}/>

              {/*<Route path="/travel" element={<Landings />}/>*/}
              <Route path="/travel/:code" element={<Landings />}/>
              <Route path="/travel/:code/:block" element={<Landings />}/>

              <Route path="/upload" element={<LoginToUploadPage/>}/>
              <Route path="/upload/files" element={<UploadPage/>}/>
              <Route path="/upload/restore" element={<RestoreToUploadPage/>}/>
              {/*<Route path="*"  element={<LazyApp />} />*/}
              <Route path="*" element={<Page404 />} />
              {/*<Route path="*" element={<PageNotFound />} />*/}

              <Route path="/partner" element={<Partner />}/>
              <Route path="/partner/statistic" element={<PartnerStatistic />}/>
              <Route path="/partner/tools" element={<PartnerTools />}/>
              <Route path="/partner/payouts" element={<PartnerPayouts />}/>

            </Routes>
          </Router>
    {/*      <Footer />*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*</LoginOnClientSideHOC>*/}
  </Suspense>
}

export default App;


export class Preloader extends React.Component {
  private viewRef:any;
  constructor(props:object) {
    super(props);
    this.viewRef = React.createRef();
  }

  componentWillUnmount() {
    this.viewRef.current.style.opacity = 0;
  }

  render() {
    return (
      <div ref={this.viewRef} className="Preloader">
        <div className="container Preloader_content">
          <div className="Preloader_content_logo">
            <BigLoader />
          </div>
          <div className="Preloader_content_text">Loading...</div>
        </div>
      </div>
    );
  }
}
