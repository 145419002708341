import {Country} from "../../entities/Country";
import {GetCountryMatrix} from "./GetCountryMatrix";


/**
 *
 */
export const FindCountryFromMatrixByUrlCode = (urlCode:string): Country|undefined =>
{
  // const matrixExists = (window.calcConfig && window.calcConfig.matrix && window.calcConfig.matrix.countries)
  // if (!matrixExists) return null;
  // return window.calcConfig.matrix.countries.find((it) => it.url_code === urlCode);

  const matrix = GetCountryMatrix();
  if (!matrix) return undefined;

  return matrix.find((it:Country) => it.url_code === urlCode);
}
